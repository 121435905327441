// Library
import Image from "next/image";
import { Link } from "@/lib/i18n";
import Ellipsis from "@/components/ui/Ellipsis/Ellipsis";
import * as m from "@/paraglide/messages";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function CourseCard({ course, empty, skeleton, ...props }) {
    if (empty) {
        return (
            <div className="bg-gray-50 dark:bg-bgDark-840 rounded-card p-5 overflow-hidden border dark:border-borderDark h-full">
                <div className="flex gap-5 items-center">
                    {/* Image */}
                    <div className="relative leading-[0]">
                        <div className="rounded-[17.544%] overflow-hidden aspect-1 w-[50px] h-[50px]">
                            <Image
                                src="/images/site/courses/icon-progress.png"
                                alt="Believemy"
                                width={50}
                                height={50}
                            />
                        </div>
                    </div>

                    {/* Details */}
                    <div className="flex-1 flex flex-col">
                        {/* Title */}
                        <div className="bold text-black dark:text-white">
                            {m.CourseCard_soon_title()}
                        </div>
                    </div>
                </div>

                {/* Summary */}
                <div className="text-gray-500 dark:text-lightBlue mt-5 flex-1 text-sm leading-relaxed">
                    {m.CourseCard_soon_summary()}
                </div>
            </div>
        );
    }

    if (skeleton) {
        return (
            <div className="bg-gray-50 dark:bg-bgDark-840 rounded-card p-5 overflow-hidden border dark:border-borderDark h-full">
                <div className="flex gap-5 items-center">
                    {/* Image */}
                    <div className="relative leading-[0]">
                        <Skeleton
                            width={50}
                            height={50}
                            containerClassName="rounded-[17.544%] overflow-hidden inline-block leading-[0]"
                        />
                    </div>

                    {/* Details */}
                    <div className="flex-1 flex flex-col">
                        {/* Title */}
                        <div className="bold text-gray-200 dark:text-gray-800 w-1/2">
                            <Skeleton />
                        </div>
                    </div>
                </div>

                {/* Summary */}
                <div className="text-gray-200 dark:text-gray-800 mt-5">
                    <Skeleton count={2.4} />
                </div>
            </div>
        );
    }

    return (
        <Link href={`/course/${course.slug}`}>
            <div className="bg-gray-50 dark:bg-bgDark-840 rounded-card p-5 hover:scale-[0.98] overflow-hidden border dark:border-borderDark hover:border-primary dark:hover:border-primary duration-150 h-full">
                <div className="flex gap-5 items-center">
                    {/* Image */}
                    <div className="relative">
                        <div className="rounded-[17.544%] overflow-hidden aspect-1 w-[50px] h-[50px]">
                            <Image
                                src={course.image}
                                alt={course.name}
                                width={50}
                                height={50}
                            />
                        </div>
                    </div>

                    {/* Details */}
                    <div className="flex-1 flex flex-col">
                        {/* Title */}
                        <div className="bold text-black dark:text-white">
                            {course.name}
                        </div>

                        {/* {course.isBelievemyOriginals && (
                            <div className="text-primary font-sourceSans text-sm">
                                Believemy Originals.
                            </div>
                        )} */}
                    </div>
                </div>

                {/* Summary */}
                <div className="text-gray-500 dark:text-lightBlue mt-5 flex-1 text-sm leading-relaxed">
                    <Ellipsis maxLines="3" disableReadMore>
                        {course.summary}
                    </Ellipsis>
                </div>
            </div>
        </Link>
    );
}
