// Libraries
import Image from "next/image";
import { secToHourMinS } from "@/helpers/secToMinS";

export default function EpisodeInline({ episode, ...props }) {
    return (
        <div
            className={`${
                !props.transparent && "bg-bgDark-700"
            } p-5 flex md:flex-row flex-col items-center md:gap-3 text-sm hover:bg-bgDark-550 duration-150 cursor-pointer dark:text-white`}
            onClick={props.onClick}
            key={props.key}
        >
            <div className="text-primary md:w-auto w-full md:text-left text-center md:p-0 p-1 md:rounded-none rounded-t">
                {episode.position}
            </div>
            <div className="md:w-32 w-full md:my-0 my-4">
                <div className="w-full h-0 aspect-w-16 aspect-h-9 relative">
                    {episode.thumbnail ? (
                        <Image
                            src={episode.thumbnail}
                            alt={episode.title}
                            layout="fill"
                            className="rounded"
                        />
                    ) : (
                        <div className="w-32 aspect-w-16 aspect-h-9 bg-primary rounded"></div>
                    )}
                </div>
            </div>
            <div className="font-bold flex-1">{episode.title}</div>
            <div>
                <div>{secToHourMinS(episode.duration)}</div>
            </div>
        </div>
    );
}
