export default function InfoBadge({ type, children }) {
    if (type == "green") {
        return (
            <span className="text-green-500 dark:text-green-400 bg-green-500/10 rounded-full px-3 py-1 text-sm">
                {children}
            </span>
        );
    } else if (type == "yellow") {
        return (
            <span className="text-yellow-500 dark:text-yellow-400 bg-yellow-500/10 rounded-full px-3 py-1 text-sm">
                {children}
            </span>
        );
    } else if (type == "blue") {
        return (
            <span className="text-blue-500 dark:text-blue-400 bg-blue-500/10 rounded-full px-3 py-1 text-sm">
                {children}
            </span>
        );
    } else if (type == "gray") {
        return (
            <span className="text-gray-500 dark:text-gray-400 bg-gray-500/10 rounded-full px-3 py-1 text-sm">
                {children}
            </span>
        );
    } else {
        return (
            <span className="text-red-500 dark:text-red-400 bg-red-500/10 rounded-full px-3 py-1 text-sm">
                {children}
            </span>
        );
    }
}
