"use client";

// Libraries
import { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import ProgressionCard from "../../content/ProgressionCard/ProgressionCard";
import ArticleCard from "../../ArticleCard/ArticleCard";
import CourseCard from "../../content/CourseCard/CourseCard";
import BookedEvent from "../../content/BookedEvent/BookedEvent";
import Episode from "../../content/Episode/Episode";
import SerieCard from "../../content/SerieCard/SerieCard";
import Mentor from "../../Library/Mentor/Mentor";
import { Link } from "@/lib/i18n";
import Highlight from "../../content/Highlight/Highlight";
import * as m from "@/paraglide/messages";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Slide({
    title,
    type,
    courses,
    articles,
    lastCourses,
    events,
    session,
    news,
    series,
    mentors,
    episodes,
    showDetails = false,
    numberOfSlidesToShowFromComponent = 4,
    ...props
}) {
    const calculateSlidesToShow = () => {
        if (typeof window === "undefined") return 3;
        const screenWidth = window.innerWidth;

        if (
            type === "progressions" ||
            type === "courses" ||
            type === "events"
        ) {
            if (screenWidth < 1024) return 1;
            if (screenWidth < 1536) return 2;
            if (screenWidth < 2000) return 3;
            return 4;
        }

        if (type === "articles" || type === "series" || type === "mentors") {
            if (screenWidth < 950) return 1;
            if (screenWidth < 1300) return 2;
            if (screenWidth < 1536) return 3;
            if (screenWidth < 2000) return 4;
            return 5;
        }

        if (type === "new" || type === "episodes") {
            if (screenWidth < 768) return 2;
            if (screenWidth < 1024) return 3;
            if (screenWidth < 1340) return 4;
            if (screenWidth < 2000) return 5;
            return 6;
        }

        return 3; // Default fallback
    };

    // States
    const [numberOfSlidesToShow, setNumberOfSlidesToShow] = useState(
        numberOfSlidesToShowFromComponent,
    );
    const [emblaRef, emblaApi] = useEmblaCarousel({
        align: "start",
        containScroll: false,
        slidesToScroll: numberOfSlidesToShow,
    });
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(true);

    // Methods
    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev();
    }, [emblaApi]);

    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext();
    }, [emblaApi]);

    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        setPrevBtnEnabled(emblaApi.canScrollPrev());
        setNextBtnEnabled(emblaApi.canScrollNext());
    }, [emblaApi]);

    const handleResize = () => {
        const slides = calculateSlidesToShow();
        setNumberOfSlidesToShow(slides);
        if (emblaApi) {
            emblaApi.reInit({
                align: "start",
                containScroll: false,
                slidesToScroll: slides,
            });
        }
    };

    // const handleResize = () => {
    //     const screenWidth = window.innerWidth;

    //     if (type == "progressions" || type == "courses" || type == "events") {
    //         if (screenWidth < 1024) {
    //             // Modifier les options pour les petits écrans
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 1,
    //             });
    //             setNumberOfSlidesToShow(1);
    //         } else if (screenWidth < 1536) {
    //             // Modifier les options pour les écrans de taille moyenne
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 2,
    //             });
    //             setNumberOfSlidesToShow(2);
    //         } else if (screenWidth < 2000) {
    //             // Modifier les options pour les grands écrans
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 3,
    //             });
    //             setNumberOfSlidesToShow(3);
    //         } else {
    //             // Modifier les options pour les très grands écrans
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 4,
    //             });
    //             setNumberOfSlidesToShow(4);
    //         }
    //     }

    //     if (type == "articles" || type == "series" || type == "mentors") {
    //         if (screenWidth < 950) {
    //             // Modifier les options pour les écrans vraiment petits
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 1,
    //             });
    //             setNumberOfSlidesToShow(1);
    //         } else if (screenWidth < 1300) {
    //             // Modifier les options pour les très petits écrans
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 2,
    //             });
    //             setNumberOfSlidesToShow(2);
    //         } else if (screenWidth < 1536) {
    //             // Modifier les options pour les petits écrans
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 3,
    //             });
    //             setNumberOfSlidesToShow(3);
    //         } else if (screenWidth < 2000) {
    //             // Modifier les options pour les écrans de taille moyenne
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 4,
    //             });
    //             setNumberOfSlidesToShow(4);
    //         } else {
    //             // Modifier les options pour les grands écrans
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 5,
    //             });
    //             setNumberOfSlidesToShow(5);
    //         }
    //     }

    //     if (type == "new" || type == "episodes") {
    //         if (screenWidth < 768) {
    //             // Modifier les options pour les écrans vraiment petits
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 2,
    //             });
    //             setNumberOfSlidesToShow(2);
    //         } else if (screenWidth < 1024) {
    //             // Modifier les options pour les petits écrans
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 3,
    //             });
    //             setNumberOfSlidesToShow(3);
    //         } else if (screenWidth < 1340) {
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 4,
    //             });
    //             setNumberOfSlidesToShow(4);
    //         } else if (screenWidth < 1536) {
    //             // Modifier les options pour les écrans de taille moyenne
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 4,
    //             });
    //             setNumberOfSlidesToShow(4);
    //         } else if (screenWidth < 2000) {
    //             // Modifier les options pour les grands écrans
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 5,
    //             });
    //             setNumberOfSlidesToShow(5);
    //         } else {
    //             // Modifier les options pour les très grands écrans
    //             emblaApi.reInit({
    //                 align: "start",
    //                 containScroll: false,
    //                 slidesToScroll: 6,
    //             });
    //             setNumberOfSlidesToShow(6);
    //         }
    //     }

    //     setHasBeenProcessed(true);
    // };

    // Cycles

    useEffect(() => {
        if (!emblaApi) return;
        setPrevBtnEnabled(emblaApi.canScrollPrev());
        setNextBtnEnabled(emblaApi.canScrollNext());
    });

    useEffect(() => {
        if (!emblaApi) return;
        emblaApi.on("select", onSelect);
        onSelect();
    }, [emblaApi, onSelect]);

    useEffect(() => {
        if (!emblaApi) return;

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [emblaApi]);

    useEffect(() => {
        if (!emblaApi) return;

        handleResize();
    }, [courses, articles, lastCourses, events, news, series, mentors]);

    // Buttons
    const buttons = (
        <>
            {prevBtnEnabled && (
                <div
                    className="absolute left-0 top-0 bottom-0 cursor-pointer bg-gray-50 dark:bg-bgDark-700 bg-opacity-70 hover:bg-opacity-95 dark:bg-opacity-70 dark:hover:bg-opacity-95 duration-150 flex h-full items-center justify-center group"
                    onClick={scrollPrev}
                    aria-label="Précédent"
                    style={{
                        width: "4%",
                        zIndex: 5,
                    }}
                >
                    <button className="embla__prev" aria-label="Précédent">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="dark:text-white h-8 w-8 group-hover:text-primary duration-150"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                fillRule="evenodd"
                                d="M15.488 4.43a.75.75 0 0 1 .081 1.058L9.988 12l5.581 6.512a.75.75 0 1 1-1.138.976l-6-7a.75.75 0 0 1 0-.976l6-7a.75.75 0 0 1 1.057-.081Z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </div>
            )}
            {nextBtnEnabled && (
                <div
                    className="absolute right-0 top-0 bottom-0 bg-gray-50 dark:bg-bgDark-700 bg-opacity-70 hover:bg-opacity-95 dark:bg-opacity-70 dark:hover:bg-opacity-95 cursor-pointer flex h-full items-center justify-center group"
                    onClick={scrollNext}
                    aria-label="Suivant"
                    style={{
                        width: "4%",
                        zIndex: 5,
                    }}
                >
                    <button className="embla__next" aria-label="Suivant">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="dark:text-white h-8 w-8 group-hover:text-primary duration-150"
                        >
                            <path
                                fill="currentColor"
                                fillRule="evenodd"
                                d="M8.512 4.43a.75.75 0 0 1 1.057.082l6 7a.75.75 0 0 1 0 .976l-6 7a.75.75 0 0 1-1.138-.976L14.012 12L8.431 5.488a.75.75 0 0 1 .08-1.057Z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </div>
            )}
        </>
    );

    return (
        <div style={{ margin: type != "new" && "45px 0" }}>
            <div
                className={`text-xl ${
                    type != "new" ? "dark:text-white" : "text-white"
                } font-bold lg:m-container m-containerMobile`}
                style={{ marginBottom: "0.5em" }}
            >
                {props.skeleton ? <Skeleton width={200} /> : title}
            </div>

            {type == "progressions" && (
                <>
                    {courses.length != 0 && (
                        <div className="relative overflow-hidden">
                            <div className="embla lg:p-container p-containerMobile">
                                {buttons}
                                <div className="embla__viewport" ref={emblaRef}>
                                    <div className="embla__container">
                                        {courses.map((course) => (
                                            <div
                                                className="embla__slide"
                                                key={`progress-${course.id}`}
                                                style={{
                                                    flex: `0 0 calc(100% / ${numberOfSlidesToShow})`,
                                                }}
                                            >
                                                <ProgressionCard
                                                    course={
                                                        course.product
                                                            ? course.product
                                                            : course.program
                                                    }
                                                    progression={
                                                        course.progression
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {type == "courses" && (
                <>
                    {lastCourses.length != 0 ? (
                        <div className="mb-5 relative overflow-hidden">
                            <div className="embla lg:p-container p-containerMobile">
                                {buttons}
                                <div className="embla__viewport" ref={emblaRef}>
                                    <div className="embla__container">
                                        {lastCourses.map((course) => (
                                            <div
                                                className="embla__slide"
                                                key={`courses-${course.id}`}
                                                style={{
                                                    flex: `0 0 calc(100% / ${numberOfSlidesToShow})`,
                                                }}
                                            >
                                                <CourseCard course={course} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="mb-5 relative overflow-hidden">
                            <div className="embla lg:p-container p-containerMobile">
                                {buttons}
                                <div className="embla__viewport" ref={emblaRef}>
                                    <div className="embla__container">
                                        <div
                                            className="embla__slide"
                                            style={{
                                                flex: `0 0 calc(100% / ${numberOfSlidesToShow})`,
                                            }}
                                        >
                                            <CourseCard empty />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {type == "new" && (
                <>
                    {news.length != 0 ? (
                        <div className="mb-5 relative overflow-hidden">
                            <div className="embla lg:p-container p-containerMobile">
                                {buttons}
                                <div className="embla__viewport" ref={emblaRef}>
                                    <div className="embla__container">
                                        {news.map((highlight) => (
                                            <div
                                                className="embla__slide"
                                                key={`new-${highlight.id}`}
                                                style={{
                                                    flex: `0 0 calc(100% / ${numberOfSlidesToShow})`,
                                                }}
                                            >
                                                <Highlight
                                                    data={highlight}
                                                    isHighlighted={
                                                        props.highlightedEpisode
                                                            .id === highlight.id
                                                    }
                                                    onClick={
                                                        props.setHighlightedEpisode
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="mb-5 relative overflow-hidden">
                            <div className="embla lg:p-container p-containerMobile">
                                {buttons}
                                <div className="embla__viewport" ref={emblaRef}>
                                    <div className="embla__container">
                                        {/* 10 times */}
                                        {Array.from({ length: 10 }).map(
                                            (_, index) => (
                                                <div
                                                    key={`new-${index}`}
                                                    className="embla__slide"
                                                    style={{
                                                        flex: `0 0 calc(100% / ${numberOfSlidesToShow})`,
                                                    }}
                                                >
                                                    <div key={index}>
                                                        <Skeleton
                                                            className="aspect-[16/9]"
                                                            style={{
                                                                borderRadius:
                                                                    "0.75rem",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            ),
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {type == "episodes" && (
                <>
                    {episodes.length != 0 && (
                        <div className="mb-5 relative overflow-hidden">
                            <div className="embla lg:p-container p-containerMobile">
                                {buttons}
                                <div className="embla__viewport" ref={emblaRef}>
                                    <div className="embla__container">
                                        {episodes.map((episode, index) => (
                                            <>
                                                {episode.isOnline && (
                                                    <div
                                                        className="embla__slide"
                                                        key={`new-${episode.id}`}
                                                        style={{
                                                            flex: `0 0 calc(100% / ${numberOfSlidesToShow})`,
                                                        }}
                                                    >
                                                        <Link
                                                            href={`/series/${props.slug}/${props.season.position}/${episode.position}`}
                                                        >
                                                            <Episode
                                                                data={episode}
                                                                withoutStatus
                                                                showDetails={
                                                                    showDetails
                                                                }
                                                                index={index}
                                                            />
                                                        </Link>
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {type == "events" && (
                <>
                    {events.length != 0 && (
                        <div className="mb-5 relative overflow-hidden">
                            <div className="embla lg:p-container p-containerMobile">
                                {buttons}
                                <div className="embla__viewport" ref={emblaRef}>
                                    <div className="embla__container">
                                        {events.map((event) => (
                                            <div
                                                className="embla__slide"
                                                key={`event-${event.id}`}
                                                style={{
                                                    flex: `0 0 calc(100% / ${numberOfSlidesToShow})`,
                                                }}
                                            >
                                                <BookedEvent
                                                    event={event}
                                                    session={session}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {type == "articles" && (
                <>
                    {articles.length != 0 ? (
                        <div className="mb-5 relative overflow-hidden">
                            <div className="embla lg:p-container p-containerMobile">
                                {buttons}
                                <div className="embla__viewport" ref={emblaRef}>
                                    <div className="embla__container">
                                        {articles
                                            .slice(0, 15)
                                            .map((article, index) => (
                                                <div
                                                    className="embla__slide"
                                                    key={`article-${article.id}`}
                                                    style={{
                                                        flex: `0 0 calc(100% / ${numberOfSlidesToShow})`,
                                                    }}
                                                >
                                                    <ArticleCard
                                                        article={article}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="mb-5 relative overflow-hidden">
                            <div className="embla lg:p-container p-containerMobile">
                                {buttons}
                                <div className="embla__viewport" ref={emblaRef}>
                                    <div className="embla__container">
                                        <div
                                            className="embla__slide"
                                            style={{
                                                flex: `0 0 calc(100% / ${numberOfSlidesToShow})`,
                                            }}
                                        >
                                            <ArticleCard empty />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {type == "series" && (
                <>
                    {series.length != 0 && (
                        <div className="mb-5 relative overflow-hidden">
                            <div className="embla lg:p-container p-containerMobile">
                                {buttons}
                                <div className="embla__viewport" ref={emblaRef}>
                                    <div className="embla__container">
                                        {series.map((serie, index) => (
                                            <div
                                                className="embla__slide"
                                                key={`serie-${index}`}
                                                style={{
                                                    flex: `0 0 calc(100% / ${numberOfSlidesToShow})`,
                                                }}
                                            >
                                                <SerieCard
                                                    serie={serie}
                                                    setModal={props.setModal}
                                                    setModalIsOpen={
                                                        props.setModalIsOpen
                                                    }
                                                    modalIsOpen={
                                                        props.modalIsOpen
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {type == "mentors" && (
                <>
                    {mentors.length != 0 && (
                        <div className="mb-5 relative overflow-hidden">
                            <div className="embla lg:p-container p-containerMobile">
                                {buttons}
                                <div className="embla__viewport" ref={emblaRef}>
                                    <div className="embla__container">
                                        {mentors.map((mentor, index) => (
                                            <div
                                                className="embla__slide"
                                                key={`mentor-${index}`}
                                                style={{
                                                    flex: `0 0 calc(100% / ${numberOfSlidesToShow})`,
                                                }}
                                            >
                                                <Mentor
                                                    mentor={mentor}
                                                    fromSlider
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
