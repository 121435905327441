// Components
import Image from "next/image";
import Icon from "../../ui/Icon/Icon";
import * as m from "@/paraglide/messages";

export default function Episode({
    data: episode,
    isHighlighted,
    showDetails,
    ...props
}) {
    return (
        <>
            <div
                className="relative group"
                onClick={() => props.onClick(episode)}
            >
                <div
                    className={`rounded-card overflow-hidden relative cursor-pointer group-hover:scale-[0.98] border duration-150 border-transparent group-hover:border-primary`}
                >
                    {/* Believemy Origignals ? */}
                    {episode.isBelievemyOriginals && (
                        <div className="absolute left-3 top-3 z-10">
                            <Icon white ratio="0.015" />
                        </div>
                    )}
                    {episode.thumbnail ? (
                        <Image
                            src={episode.thumbnail}
                            alt={episode.title}
                            width={1920 * 0.3}
                            height={1080 * 0.3}
                            className="rounded"
                        />
                    ) : (
                        <div className="aspect-w-16 aspect-h-9 bg-primary rounded"></div>
                    )}
                </div>
                {showDetails && (
                    <>
                        <h3 className="text-black dark:text-white mt-3">
                            {(props.index ?? 0) + 1}. {episode.title}
                        </h3>
                    </>
                )}
            </div>
            {!props.withoutStatus && (
                <div className="absolute bottom-0 left-0 right-0 flex justify-center">
                    <div className="px-3 py-1 text-white bg-red-500 text-xs rounded bold">
                        {Episode_episode()}
                    </div>
                </div>
            )}
        </>
    );
}
