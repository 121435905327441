// Libraries
import Image from "next/image";
import { Link } from "@/lib/i18n";
import { secToHourMin } from "../../../helpers/secToMinS";
import * as m from "@/paraglide/messages";

export default function ProgressionCard({ course, progression, ...props }) {
    if (!course) return null;

    if (
        course &&
        course.programConstructorVersion &&
        course.programConstructorVersion >= 2
    ) {
        // For program with constructor structure version >= 2
        let link = "dashboard";

        return (
            <Link href={`/accelerators/${course.slug}/${link}`}>
                <div className="bg-gray-50 dark:bg-bgDark-840 rounded-card hover:scale-[0.98] flex flex-col overflow-hidden border dark:border-borderDark hover:border-primary dark:hover:border-primary h-full duration-150">
                    <div className="flex p-5 gap-5 flex-1">
                        {/* Image */}
                        <div className="rounded-[17.544%] overflow-hidden aspect-1 w-[50px] h-[50px]">
                            <Image
                                src={course.image}
                                alt={course.name}
                                width={50}
                                height={50}
                            />
                        </div>

                        {/* Details */}
                        <div className="flex flex-col flex-1">
                            <div className="flex-1">
                                {/* Title */}
                                <div className="font-bold text-black dark:text-white mb-1">
                                    {course.name}
                                </div>

                                {/* Time */}
                                <div className="text-gray-500 dark:text-lightBlue flex items-center">
                                    {progression.secondsConsumed > 120
                                        ? m.ProgressionCard_minutesPassed({
                                              time: secToHourMin(
                                                  progression.secondsConsumed,
                                              ),
                                          })
                                        : m.ProgressionCard_minutePassed({
                                              time: secToHourMin(
                                                  progression.secondsConsumed,
                                              ),
                                          })}
                                </div>
                            </div>
                            {/* Percent */}
                            <div className="flex justify-end text-primary text-xl">
                                {progression.total} %
                            </div>
                        </div>
                    </div>
                    <div className="bg-primary bg-opacity-10 h-1">
                        <div
                            className="bg-primary h-full"
                            style={{ width: `${progression.total}%` }}
                        ></div>
                    </div>
                </div>
            </Link>
        );
    } else {
        let link = "";
        if (course.category == "program") {
            if (course.slug == "le-programme-rocket-2020") {
                if (progression && progression.part) {
                    link = `${progression.part}/${progression.position}`;
                } else {
                    link = "1/1";
                }
            } else {
                link = "dashboard";
            }
        } else {
            if (progression && progression.part) {
                link = `${progression.part}/${progression.position}`;
            } else {
                link = "1/1";
            }
        }

        return (
            <Link href={`/course/${course.slug}/${link}`}>
                <div className="bg-gray-50 dark:bg-bgDark-840 rounded-card hover:scale-[0.98] flex flex-col overflow-hidden border dark:border-borderDark hover:border-primary dark:hover:border-primary h-full duration-150">
                    <div className="flex p-5 gap-5 flex-1">
                        {/* Image */}
                        <div className="rounded-[17.544%] overflow-hidden aspect-1 w-[50px] h-[50px]">
                            <Image
                                src={course.image}
                                alt={course.name}
                                width={50}
                                height={50}
                            />
                        </div>

                        {/* Details */}
                        <div className="flex flex-col flex-1">
                            <div className="flex-1">
                                {/* Title */}
                                <div className="font-bold text-black dark:text-white mb-1">
                                    {course.name}
                                </div>

                                {/* Time */}
                                <div className="text-gray-500 dark:text-lightBlue flex items-center">
                                    {progression.secondsConsumed > 120
                                        ? m.ProgressionCard_minutesPassed({
                                              time: secToHourMin(
                                                  progression.secondsConsumed,
                                              ),
                                          })
                                        : m.ProgressionCard_minutePassed({
                                              time: secToHourMin(
                                                  progression.secondsConsumed,
                                              ),
                                          })}
                                </div>
                            </div>
                            {/* Percent */}
                            <div className="flex justify-end text-primary text-xl">
                                {progression.total} %
                            </div>
                        </div>
                    </div>
                    <div className="bg-primary bg-opacity-10 h-1">
                        <div
                            className="bg-primary h-full"
                            style={{ width: `${progression.total}%` }}
                        ></div>
                    </div>
                </div>
            </Link>
        );
    }
}
