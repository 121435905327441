// Libraries
import Image from "next/image";
import { Link } from "@/lib/i18n";
import { useEffect, useState } from "react";
import Button from "../../ui/Button/Button";
import EpisodeInline from "../Episode/EpisodeInline/EpisodeInline";
import SerieModale from "./SerieModale/SerieModale";
import * as m from "@/paraglide/messages";

export default function SerieCard({
    serie,
    modalIsOpen,
    setModalIsOpen,
    setModal,
    ...props
}) {
    // Method
    const onSerieCardCliked = () => {
        window.history.replaceState(
            null,
            "",
            `${process.env.NEXTAUTH_URL}/series/${serie.slug}`,
        );

        let modal = (
            <SerieModale serie={serie} setModalIsOpen={setModalIsOpen} />
        );

        setModal(null);
        setTimeout(() => {
            setModal(modal);
            setModalIsOpen(true);
        }, 100);
    };

    useEffect(() => {
        if (modalIsOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [modalIsOpen]);

    return (
        <div
            className="bg-bgDark-840 rounded-card overflow-hidden aspect-[3/4] border dark:border-borderDark hover:border-primary hover:scale-[0.98] duration-150 relative cursor-pointer"
            style={{
                backgroundImage: `url(${serie.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
            }}
            onClick={onSerieCardCliked}
        >
            {/* Gradient */}
            <div
                className="h-72 absolute left-0 bottom-0 right-0"
                style={{
                    background:
                        "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)",
                }}
            ></div>
            <div className="flex flex-col justify-end items-center h-full pb-10 px-5 relative">
                {/* Title */}
                <Image
                    src={serie.imageTitle}
                    alt="Title"
                    width="625"
                    height="250"
                    layout="responsive"
                />
            </div>
        </div>
    );
}
